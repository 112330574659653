import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import classes from "./styles.module.scss";

import LogoSvg from "../../logos/svg";
import { AiTwotoneMail } from "react-icons/ai";
import SEO from "../../seo";

const Home = () => {
  const query = graphql`
    query {
      hero: file(relativePath: { eq: "home_bkg.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            src
            srcSet
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);

  return (
    <>
      <SEO title="Influential Fashion" />
      <section className={classes.wrapper}>
        <div className={`container ${classes.container}`}>
          <div className={`grid--2 ${classes.flex}`}>
            <LogoSvg classList={classes.logo} />
            <div className={classes.content}>
              <h2>
                Influential fashion that empowers those to love themselves too.
              </h2>
              <p>
                For more information, please email us at{" "}
                <a href="mailto:info@lilyandlottie.com">
                  <AiTwotoneMail />
                  info@lilyandlottie.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
